<template>
  <v-container fluid id="login" class="d-flex align-center justify-center bg-loop">
    <v-row class="" align="center" justify="center">
      <v-col cols="12" sm="10" md="4" v-if="!emailSent">
      <v-card elevation="10" class="pb-6"
      :loading="isLogging"
      :disabled="isLogging !== false"
      >
        <img class="logo pt-4 mx-auto d-block" src="@/assets/Logo-Color.png">
          <h2 class="text-center py-3 pt-1">Login. Become Meetable</h2>
          <div class="error white--text" v-if="errorMessage">
              {{errorMessage}}
          </div>
        <v-card-text>

          <template v-if="showFields">
            <ValidationObserver tag="form"  ref="observer" >
            <ValidationProvider rules="required|email" v-slot="{ errors }">
              <v-text-field
              placeholder="email"
              filled hide-details="auto"

              v-model.trim="email"
              :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </ValidationObserver>
          </template>

          <template v-else>
            <auth-button :social="'google'" :logInOut="'Continue'" @authWith="signIn"></auth-button>
            <!-- <social-btns :social="'facebook'" :logInOut="'Continue'" @authWith="signIn"></social-btns> -->
            <auth-button :social="'email'" :logInOut="'Continue'" @authWith="showFields = true"></auth-button>
          </template>

          <v-card-actions class="flex-column align-center pa-0">
            <v-btn v-if="showFields" block dark large class="black submit bg-loop font-weight-bold" @click="signIn('email')">Send Magic Link <v-icon class="ml-2" color="white">mdi-email-send-outline</v-icon></v-btn>
            <v-btn v-if="showFields && host.includes('ngrok')" block dark large class="black submit bg-loop font-weight-bold" @click="signIn('email', 'ngrok')">Send Magic Link NGROK<v-icon class="ml-2" color="white">mdi-email-send-outline</v-icon></v-btn>
            <v-btn v-if="showFields" block large text class=" submit" @click="showFields = false">Show Social Logins</v-btn>
          </v-card-actions>

          <!-- <br>

          <v-divider class="d-block my-5"></v-divider>
          <div class="text-center mb-3">
            <h6 class="app-heading">
              Dont have an account?
            </h6>
            <v-btn to="/sign-up">create one</v-btn>
          </div> -->

        </v-card-text>
      </v-card>
      </v-col>

      <div class="sent-container" v-else>
        <div class="text-center white--text">
          <img class="wand" fill="#fff" src="@/assets/magic-wand.svg">
          <br>
          <v-icon size="120">mdi-email</v-icon>
          <div class="headline mb-4">Check your inbox!</div>
          <span>No passwords here, your email contains a magical login link.</span>
          <br>
          <strong>** you might have to check your spam folder 😩</strong>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  const authButton = () => import("@/components/shared/auth-button.vue")
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
//RULE: required
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  message:'This field is required.',
  computesRequired: true
});
//RULE: url
extend('email', {
  validate(value){
    // eslint-disable-next-line no-useless-escape
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (regexp.test(value))return true
            else return false
  },
  message:'Not a valid email?'
});
  export default {
    name: 'login',
    metaInfo: {
      title: 'Meetable: Login and become Meetable'
    },
    components:{
      // socialBtns,
      authButton,ValidationProvider, ValidationObserver,
    },
    data: function() {
      return {
        email: '',
        password: '',
        showFields: false,
        errorMessage: null,
        kind: null,
        emailSent: false,
        isLogging:false,
        host: 'nothing',
      }
    },
    methods: {
      signIn: function(kind, ngrok) {
        let vThis = this;
        vThis.needsValid = false;
        const email = this.email;
        const password = this.password;
        const action = kind !== 'email' ? 'loginSocial' : 'login';

        if(kind === 'email' && this.$isUndefined(ngrok)){
          vThis.$refs.observer.validate().then(results =>{
            process.env.NODE_ENV === "development" ? console.log( 'results', results ) : null;
            vThis.startLogin(action, email, null, kind)
          });
        }
        else if(kind === 'email' && !this.$isUndefined(ngrok)){
          vThis.startLogin(action, email, null, kind, ngrok)
        }
        else{
          vThis.startLogin(action, email, null, kind)
        }

      },
      startLogin(action, email, password, kind, ngrok){
        let vThis = this;
        let NGROK = this.$isUndefined(ngrok) ? false : true;
        //start loading for saving
        vThis.isLogging = 'black';
        window.localStorage.setItem('emailForSignIn', email);
        this.$store.dispatch(action, {email, password, kind, NGROK})
        .then((user) => {
          process.env.NODE_ENV === "development" ? console.log( 'user login came back', user ) : null;
          // vThis.logEvent();
          if(action === 'loginSocial'){
            vThis.$router.push('/my')
          }
          else{
            vThis.emailSent = true;
          }
        })
        .catch((err) => {
          this.errorMessage = err;
          console.log(err);
          vThis.isLogging = false;
        })
      }
      // logEvent(){
      //   let vThis = this;
      //   vThis.$logEvent('Account','Login', vThis.kind);
      // }
    },
    mounted(){
      // this.$store.dispatch('toggleDrawer', false);
      this.host = window.location.host
    },
  }
</script>

<style lang="scss">
  #login {
    // margin-top: 40px;
    height: 100%;
    position: relative;
    .logo{
      // position: absolute;
      max-width: 220px;
    }
    input {
      margin: 10px 0;
      width: 20%;
      padding: 15px;
    }
    button.submit{
      margin-top: 20px;
      width: 10%;
      cursor: pointer;
    }
    p {
      margin-top: 40px;
      font-size: 13px;
       a {
        text-decoration: underline;
        cursor: pointer;
        }
    }
    .sent-container{
    > div{
      position:relative;
    }
      .wand{
        width: 50px;
        position: absolute;
        left: 45%;
        top: 2.5%;
        z-index: 999;
      }
    }
  }
</style>
